.contact-container {
    position: relative;
    width: 90%;
    max-width: 1200px;
    text-align: center;
}

.contact-container .btn {
    display: inline-block;
    width: 90px;
    height: 90px;
    background: #fff;
    box-shadow: 0 5px 15px -5px #aaa;
    margin: 10px;
    border-radius: 30%;
    overflow: hidden;
    position: relative;
    color: #42d2ff;
}

.contact-container .btn svg {
    position: relative;
    z-index: 4;
    line-height: 90px;
    font-size: 36px;
    transition: 0.3s ease-in-out;
	top: 50%;
	transform: translateY(-50%);
}

.contact-container .gh svg {
    color: #383838;
}
    
.contact-container .gh::before {
    content: "";
    position: absolute;
    width: 120%;
    height: 120%;
    background: linear-gradient(#7e7e7e, #3a3a3a);
    transform: rotate(45deg);
    left: -110%;
    top: 90%;
}

.contact-container .in svg {
    color: #0a66c2;
}
    
.contact-container .in::before {
    content: "";
    position: absolute;
    width: 120%;
    height: 120%;
    background: linear-gradient(#2391ff, #0059b3);
    transform: rotate(45deg);
    left: -110%;
    top: 90%;
}

.contact-container .ig  svg{
    color: #e33d68;
}
    
.contact-container .ig::before {
    content: "";
    position: absolute;
    width: 120%;
    height: 120%;
    background: radial-gradient(circle at 60% 100%, #FED373 4%, #F15245 30%, #D92E7F 62%, #9B36B7 85%, #515ECF);
    transform: rotate(45deg);
    left: -110%;
    top: 90%;
}
    
.contact-container .tw svg {
    color: #00c6ff;
}
    
.contact-container .tw::before {
    content: "";
    position: absolute;
    width: 120%;
    height: 120%;
    background: linear-gradient(#00c6ff, #0072ff);
    transform: rotate(45deg);
    left: -110%;
    top: 90%;
}
    

.contact-container .btn:hover svg {
    color: #fff;
    transform: scale(1.4) translateY(-40%);
}

.contact-container .btn:hover::before {
    animation: onHover 0.7s 1;
    left: -10%;
    top: -10%;
}

@keyframes onHover {
    0% {
        left: -110%;
        top: 90%;
    }
    50% {
        left: 10%;
        top: -30%;
    }
    100% {
        top: -10%;
        left: -10%;
    }
}

.contact-container .yt svg {
color: #ff0000;
}

.contact-container .yt::before {
    content: "";
    position: absolute;
    width: 120%;
    height: 120%;
    background: linear-gradient(#ff4949, #ff0000);
    transform: rotate(45deg);
    left: -110%;
    top: 90%;
}