.hero-bg{
	background: #ffff00;
	border-radius: 20px;
}
.why-web-imp{
	background-color: #000;
	color: #fff;
	padding: 30px 25px;
	border-radius: 15px;
}
.feature li{
	font-weight: bold;
	list-style: none;
	line-height: 30px;
	margin-left: 	0px;
}
.feature li svg{
	font-size: 25px;
}
.tech-used svg{
	font-size: 100px;
	background-color: #000;
	color: #ffff00;
	padding:10px;
	border-radius: 15px
}

.tech-used.flex-container {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  text-transform: uppercase;
}

.tech-used.flex-container > div {
  width: 100px;
  margin: 10px;
  text-align: center;
  line-height: 30px;
  font-size: 20px;
}
.how-i-work{
	border-left: 2px solid #000;
	padding-bottom: 20px
}
.how-i-work .counting{
	width: 30px;
	height: 30px;
	text-align:center;
	display: block;
	background: #ffff00;
	padding: 5px;
	border: 2px solid #000;
	border-radius: 5px;
	margin-left:-30px;
	font-size: 20px;
	line-height: 15px;
	font-weight:bold;
}	

.home-cta .flex-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
}
.home-cta a:hover{
	text-decoration:none;
}
.home-cta .flex-container div {
  background-color: #000;
  color: #fff;
  font-size:35px;
  padding:20px 50px;
  border-radius: 15px;
  transition: 0.5s;
}
.home-cta .flex-container div:hover{
	background-color: #ffff00;
	color: #000;
}

/*.scn-blog*/

:root {
  --color: #3c3163;
  --transition-time: 0.5s;
}

.scn-blog .cards-wrapper {
  display: grid;
  justify-content: center;
  align-items: center;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 4rem;
  padding: 4rem;
  margin: 0 auto;
  width: max-content;
}

.scn-blog .card {
  font-family: 'Heebo';
  --bg-filter-opacity: 0.5;
  background-image: linear-gradient(rgba(0,0,0,var(--bg-filter-opacity)),rgba(0,0,0,var(--bg-filter-opacity))), var(--bg-img);
  height: 20em;
  width: 15em;
  font-size: 1.5em;
  color: white;
  border-radius: 1em;
  padding: 1em;
  /*margin: 2em;*/
  display: flex;
  align-items: flex-end;
  background-size: cover;
  background-position: center;
  transition: all, var(--transition-time);
  position: relative;
  overflow: hidden;
  border: 10px solid #ccc;
  text-decoration: none;
  box-shadow: inset 0 0 0 1000px rgba(0,0,0,.4);
}

.scn-blog .card:hover {
  transform: rotate(0);
}

.scn-blog .card h1 {
  margin: 0;
  font-size: 1.5em;
  line-height: 1.2em;
}

.scn-blog .card p {
  font-size: 0.75em;
  font-family: 'Open Sans';
  margin-top: 0.5em;
  line-height: 2em;
}

.scn-blog .card .tags {
  display: flex;
}

.scn-blog .card .tags .tag {
  font-size: 0.75em;
  background: rgba(255,255,255,0.5);
  border-radius: 0.3rem;
  padding: 0 0.5em;
  margin-right: 0.5em;
  line-height: 1.5em;
  transition: all, var(--transition-time);
}

.scn-blog .card:hover .tags .tag {
  background: var(--color);
  color: white;
}

.scn-blog .card .date {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 0.75em;
  padding: 1em;
  line-height: 1em;
  opacity: .8;
}

.scn-blog .card:before, .card:after {
  content: '';
  transform: scale(0);
  transform-origin: top left;
  border-radius: 50%;
  position: absolute;
  left: -50%;
  top: -50%;
  z-index: -5;
  transition: all, var(--transition-time);
  transition-timing-function: ease-in-out;
}

.scn-blog .card:before {
  background: #ddd;
  width: 250%;
  height: 250%;
}

.scn-blog .card:after {
  background: white;
  width: 200%;
  height: 200%;
}

.scn-blog .card:hover {
  color: var(--color);
}

.scn-blog .card:hover:before, .card:hover:after {
  transform: scale(1);
}

.scn-blog .card-grid-space .num {
  font-size: 3em;
  margin-bottom: 1.2rem;
  margin-left: 1rem;
}



/* MEDIA QUERIES */
@media screen and (max-width: 1285px) {
  .scn-blog .cards-wrapper {
    grid-template-columns: 1fr 1fr;
  }
}

@media screen and (max-width: 900px) {
  .scn-blog .cards-wrapper {
    grid-template-columns: 1fr;
  }
  .scn-blog .card-grid-space .num {
    /margin-left: 0;
    /text-align: center;
  }
}

@media screen and (max-width: 500px) {
  .scn-blog .cards-wrapper {
    padding: 4rem 2rem;
  }
  .scn-blog .card {
    max-width: calc(100vw - 4rem);
  }
}

/*Web Storeis Widget CSS*/
#mss3 * { box-sizing: border-box; } .mpsa {scroll-behavior: smooth;display: flex;flex-wrap: nowrap;max-width: 100%;overflow-x: auto;overflow-y: hidden;padding: 15px;height: auto} #mss3::-webkit-scrollbar {width: 0;height: 0} #mss3 .msmd::after { content: '';position: absolute;top: 0;left: 0;height: 230px;width: 100px;background: linear-gradient(to right,#e6e6e6, rgba(255,255,255,0.5), #e6e6e6);overflow: hidden;animation: shimmer 1.5s ease infinite;} @keyframes shimmer { 0% {left: -150px} 100%{left: 150px}} #mss3 .msmd { position: relative;margin: 0 13px 0 0;min-width: 173px;cursor: pointer;height: 230px;width: 173px;border-radius: 15px;background: #e6e6e6;overflow: hidden}