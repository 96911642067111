@import url(https://fonts.googleapis.com/css2?family=Abel&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Pacifico&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Pacifico&family=Reggae+One&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #fefaf6;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Move the background and make it smaller. */
/* Animation shown when entering the page and after the hover animation. */
@keyframes rainbow-text-simple-animation-rev {
    0% {
        background-size: 650%;
    }
    40% {
        background-size: 650%;
    }
    100% {
        background-size: 100%;
    }
}

/* Move the background and make it larger. */
/* Animation shown when hovering over the text. */
@keyframes rainbow-text-simple-animation {
    0% {
        background-size: 100%;
    }
    80% {
        background-size: 650%;
    }
    100% {
        background-size: 650%;
    }
}
.topnav {
  overflow: hidden;
  background-color: rgba(220, 220, 220, 0.5);
  position:fixed;
  width:90%;
  left:5%;
  top:10px;
  -webkit-backdrop-filter: blur(12px);
    backdrop-filter: blur(12px);
    border-radius: 15px;
    text-align:center;
	z-index:999;
}

.topnav a {
  display: inline-block;
  color: #000;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  font-size: 17px;
  position:relative;
  text-transform: uppercase;
  font-weight: bold;
}
.topnav a:after {
        position: absolute;
        content: '';
        height: 2px;
		  /* adjust this to move up and down. you may have to adjust the line height of the paragraph if you move it down a lot. */
        bottom: 10px; 


       /****** 
       optional values below 
       ******/
        
        /* center - (optional) use with adjusting width   */
        margin: 0 auto;
		  left: 0;
        right: 0;
		  width: 0%;
		  background: #fff;
		  
		  /* optional animation */
    transition:.5s;
    }
	 

	 /* optional hover classes used with anmiation */
	  .topnav a:hover:after {
		  width: 80%;
		  background: #F4B400;
    }

.topnav a.active {
  color: orange;
}

.topnav .icon {
  display: none;
}
.topnav a:first-child {display: none;}
@media screen and (max-width: 600px) {
	.topnav a:first-child {display: inline-block;}
  .topnav a:not(:first-child) {display: none;}
  .topnav a.icon {
    float: right;
    display: block;
  }
}

@media screen and (max-width: 600px) {
  .topnav.responsive {position: fixed;}
  .topnav.responsive .icon {
    position: absolute;
    right: 0;
    top: 0;
  }
  .topnav.responsive a {
    float: none;
    display: block;
    text-align: center;
  }
}

.ctoa{
	background:yellow;
    margin:20px;
	margin-top:50px;
    border-radius:15px
}
.y-btn{
	background-color: #000;
	color: #fff;
	padding: 6px 16px;
	border: 2px solid #ffff00;
	border-radius: 12px;
	text-decoration:none;
	transition: 0.5s;
}
.y-btn:hover{
	text-decoration:none;
	background-color: #ffff00;
	color: #000;
	border: 2px solid #000;
}
.bg-y{
	background: #ffff00;
	padding: 2px 10px;
	border-radius: 12px;
}
#top{
	padding:35px;
}

.icon_pulse {
    animation: circle 1.2s steps(8) infinite;
}

@keyframes circle {
    from {
        transform: rotate(90deg);
    }

    to {
        transform: rotate(450deg);
    }
}

.footer-menu{
	background-color: #e5ffe0;
	border-top: 1px solid green;
	border-bottom: 1px solid green;
	padding-top: 30px;
	padding-bottom: 20px;
}
.footer-menu ul{
	list-style-type:none;
}
.footer-menu ul li{
	display:inline-block;
	margin-left: 15px;
}
.hero-bg{
	background: #ffff00;
	border-radius: 20px;
}
.why-web-imp{
	background-color: #000;
	color: #fff;
	padding: 30px 25px;
	border-radius: 15px;
}
.feature li{
	font-weight: bold;
	list-style: none;
	line-height: 30px;
	margin-left: 	0px;
}
.feature li svg{
	font-size: 25px;
}
.tech-used svg{
	font-size: 100px;
	background-color: #000;
	color: #ffff00;
	padding:10px;
	border-radius: 15px
}

.tech-used.flex-container {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  text-transform: uppercase;
}

.tech-used.flex-container > div {
  width: 100px;
  margin: 10px;
  text-align: center;
  line-height: 30px;
  font-size: 20px;
}
.how-i-work{
	border-left: 2px solid #000;
	padding-bottom: 20px
}
.how-i-work .counting{
	width: 30px;
	height: 30px;
	text-align:center;
	display: block;
	background: #ffff00;
	padding: 5px;
	border: 2px solid #000;
	border-radius: 5px;
	margin-left:-30px;
	font-size: 20px;
	line-height: 15px;
	font-weight:bold;
}	

.home-cta .flex-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
}
.home-cta a:hover{
	text-decoration:none;
}
.home-cta .flex-container div {
  background-color: #000;
  color: #fff;
  font-size:35px;
  padding:20px 50px;
  border-radius: 15px;
  transition: 0.5s;
}
.home-cta .flex-container div:hover{
	background-color: #ffff00;
	color: #000;
}

/*.scn-blog*/

:root {
  --color: #3c3163;
  --transition-time: 0.5s;
}

.scn-blog .cards-wrapper {
  display: grid;
  justify-content: center;
  align-items: center;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 4rem;
  padding: 4rem;
  margin: 0 auto;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
}

.scn-blog .card {
  font-family: 'Heebo';
  --bg-filter-opacity: 0.5;
  background-image: linear-gradient(rgba(0,0,0,var(--bg-filter-opacity)),rgba(0,0,0,var(--bg-filter-opacity))), var(--bg-img);
  height: 20em;
  width: 15em;
  font-size: 1.5em;
  color: white;
  border-radius: 1em;
  padding: 1em;
  /*margin: 2em;*/
  display: flex;
  align-items: flex-end;
  background-size: cover;
  background-position: center;
  transition: all, 0.5s;
  transition: all, var(--transition-time);
  position: relative;
  overflow: hidden;
  border: 10px solid #ccc;
  text-decoration: none;
  box-shadow: inset 0 0 0 1000px rgba(0,0,0,.4);
}

.scn-blog .card:hover {
  transform: rotate(0);
}

.scn-blog .card h1 {
  margin: 0;
  font-size: 1.5em;
  line-height: 1.2em;
}

.scn-blog .card p {
  font-size: 0.75em;
  font-family: 'Open Sans';
  margin-top: 0.5em;
  line-height: 2em;
}

.scn-blog .card .tags {
  display: flex;
}

.scn-blog .card .tags .tag {
  font-size: 0.75em;
  background: rgba(255,255,255,0.5);
  border-radius: 0.3rem;
  padding: 0 0.5em;
  margin-right: 0.5em;
  line-height: 1.5em;
  transition: all, 0.5s;
  transition: all, var(--transition-time);
}

.scn-blog .card:hover .tags .tag {
  background: #3c3163;
  background: var(--color);
  color: white;
}

.scn-blog .card .date {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 0.75em;
  padding: 1em;
  line-height: 1em;
  opacity: .8;
}

.scn-blog .card:before, .card:after {
  content: '';
  transform: scale(0);
  transform-origin: top left;
  border-radius: 50%;
  position: absolute;
  left: -50%;
  top: -50%;
  z-index: -5;
  transition: all, 0.5s;
  transition: all, var(--transition-time);
  transition-timing-function: ease-in-out;
}

.scn-blog .card:before {
  background: #ddd;
  width: 250%;
  height: 250%;
}

.scn-blog .card:after {
  background: white;
  width: 200%;
  height: 200%;
}

.scn-blog .card:hover {
  color: #3c3163;
  color: var(--color);
}

.scn-blog .card:hover:before, .card:hover:after {
  transform: scale(1);
}

.scn-blog .card-grid-space .num {
  font-size: 3em;
  margin-bottom: 1.2rem;
  margin-left: 1rem;
}



/* MEDIA QUERIES */
@media screen and (max-width: 1285px) {
  .scn-blog .cards-wrapper {
    grid-template-columns: 1fr 1fr;
  }
}

@media screen and (max-width: 900px) {
  .scn-blog .cards-wrapper {
    grid-template-columns: 1fr;
  }
  .scn-blog .card-grid-space .num {
    /margin-left: 0;
    /text-align: center;
  }
}

@media screen and (max-width: 500px) {
  .scn-blog .cards-wrapper {
    padding: 4rem 2rem;
  }
  .scn-blog .card {
    max-width: calc(100vw - 4rem);
  }
}

/*Web Storeis Widget CSS*/
#mss3 * { box-sizing: border-box; } .mpsa {scroll-behavior: smooth;display: flex;flex-wrap: nowrap;max-width: 100%;overflow-x: auto;overflow-y: hidden;padding: 15px;height: auto} #mss3::-webkit-scrollbar {width: 0;height: 0} #mss3 .msmd::after { content: '';position: absolute;top: 0;left: 0;height: 230px;width: 100px;background: linear-gradient(to right,#e6e6e6, rgba(255,255,255,0.5), #e6e6e6);overflow: hidden;animation: shimmer 1.5s ease infinite;} @keyframes shimmer { 0% {left: -150px} 100%{left: 150px}} #mss3 .msmd { position: relative;margin: 0 13px 0 0;min-width: 173px;cursor: pointer;height: 230px;width: 173px;border-radius: 15px;background: #e6e6e6;overflow: hidden}
.contact-container {
    position: relative;
    width: 90%;
    max-width: 1200px;
    text-align: center;
}

.contact-container .btn {
    display: inline-block;
    width: 90px;
    height: 90px;
    background: #fff;
    box-shadow: 0 5px 15px -5px #aaa;
    margin: 10px;
    border-radius: 30%;
    overflow: hidden;
    position: relative;
    color: #42d2ff;
}

.contact-container .btn svg {
    position: relative;
    z-index: 4;
    line-height: 90px;
    font-size: 36px;
    transition: 0.3s ease-in-out;
	top: 50%;
	transform: translateY(-50%);
}

.contact-container .gh svg {
    color: #383838;
}
    
.contact-container .gh::before {
    content: "";
    position: absolute;
    width: 120%;
    height: 120%;
    background: linear-gradient(#7e7e7e, #3a3a3a);
    transform: rotate(45deg);
    left: -110%;
    top: 90%;
}

.contact-container .in svg {
    color: #0a66c2;
}
    
.contact-container .in::before {
    content: "";
    position: absolute;
    width: 120%;
    height: 120%;
    background: linear-gradient(#2391ff, #0059b3);
    transform: rotate(45deg);
    left: -110%;
    top: 90%;
}

.contact-container .ig  svg{
    color: #e33d68;
}
    
.contact-container .ig::before {
    content: "";
    position: absolute;
    width: 120%;
    height: 120%;
    background: radial-gradient(circle at 60% 100%, #FED373 4%, #F15245 30%, #D92E7F 62%, #9B36B7 85%, #515ECF);
    transform: rotate(45deg);
    left: -110%;
    top: 90%;
}
    
.contact-container .tw svg {
    color: #00c6ff;
}
    
.contact-container .tw::before {
    content: "";
    position: absolute;
    width: 120%;
    height: 120%;
    background: linear-gradient(#00c6ff, #0072ff);
    transform: rotate(45deg);
    left: -110%;
    top: 90%;
}
    

.contact-container .btn:hover svg {
    color: #fff;
    transform: scale(1.4) translateY(-40%);
}

.contact-container .btn:hover::before {
    animation: onHover 0.7s 1;
    left: -10%;
    top: -10%;
}

@keyframes onHover {
    0% {
        left: -110%;
        top: 90%;
    }
    50% {
        left: 10%;
        top: -30%;
    }
    100% {
        top: -10%;
        left: -10%;
    }
}

.contact-container .yt svg {
color: #ff0000;
}

.contact-container .yt::before {
    content: "";
    position: absolute;
    width: 120%;
    height: 120%;
    background: linear-gradient(#ff4949, #ff0000);
    transform: rotate(45deg);
    left: -110%;
    top: 90%;
}
::selection{
	background: #e2e8ff;
    color: #001d9b;
}
h1,h2,h3,h4{
	font-family:"Reggae One";
}

@media only screen and (max-width: 576px) {
	.notch-container{
		display:none !important;
	}
	object{
		width: 100% !important;
	}
}
object{
	width: 60%;
}
.work-img{
	width: 100%;
	transition: 0.3s;
	box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
}
.work-img:hover{
	box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
}

/*below css for eye*/
.eye-container {
	display:flex;
			justify-content: center;
			align-items: center;
			position: fixed;
			top:0;
			width:50px;
			height:50px;
			padding: 5px;
			border-radius: 0 0 50% 50%;
			/*backgroundColor:"#ffff4d",*/
			z-index:9999;
}
.c-logo{
align-items:center;
background:url(http://sachinthakur.in/eye.svg) 50% no-repeat;
display:flex;
height:20px;
justify-content:center;
position:relative;
width:28px;z-index:10
}
.is-active.c-logo:before{
	
	transform: translate3d(-50%, -80%, 0);
}
.c-logo,.c-logo:before{
transition:all .2s cubic-bezier(.175,.885,.32,1.275)
}
.c-logo:before{
background:url(http://sachinthakur.in/lash.svg) 50% no-repeat;
content:"";
display:block;
height:22px;
left:50%;

opacity:1;
/*transform: translate3d(-50%, -80%, 0);*/

position:absolute;
top:0;
transform:translate3d(-50%,-50%,0);
width:23px;
z-index:1
}
.c-logo__eye{
align-items:center;
display:flex;
height:16px;
justify-content:center;
position:relative;
width:16px
}
.is-active.c-logo__iris{
	transform: scale(1.4);
}
.c-logo__iris{
background:#fefaf6;
border-radius:50%;
bottom:3px;
height:10px;
position:relative;
transition:all .2s ease-in-out;
width:10px
}

/*CSS for Eye opening*/
			.eye-container:hover .c-logo__iris{
				transform: scale(1.4);
			}
			.eye-container:hover .c-logo:before{				
				transform: translate3d(-50%, -80%, 0);
			}

			.blog-btn{
				box-shadow: 0px 0px 10px rgba(0,0,0,0.2);
				border-radius:100px;
				padding:15px 35px;
				margin-left:20px;
				margin-top:20px;
				font-size:18px;
				text-transform:capitalize;
				text-overflow: ellipsis;
				white-space: nowrap;
				overflow: hidden;
				max-width:300px;
			}
			.blog-btn:hover{
				color: #fff;
				transform:scale(1.1);
			}
			#blog-btn1{
				background: #e3fafc;
				transition: 0.3s;
			}
			#blog-btn1:hover{
				background: #22b8cf;
			}
			#blog-btn2{
				background: #fff5f5;
				transition: 0.3s;
			}
			#blog-btn2:hover{
				background: #ff6b6b;
			}
			#blog-btn3{
				background: #e6fcf5;
				transition: 0.3s;
			}
			#blog-btn3:hover{
				background: #20c997;
			}
			#blog-btn4{
				background: #fff9db;
				transition: 0.3s;
			}
			#blog-btn4:hover{
				background: #fcc419;
			}
			#blog-btn5{
				background: #f3f0ff;
				transition: 0.3s;
			}
			#blog-btn5:hover{
				background: #845ef7;
			}
			#blog-btn6{
				background: #e7f5ff;
				transition: 0.3s;
				display:none;
			}
			#blog-btn6:hover{
				background: #339af0;
			}
			#blog-btn7{
				background: #f4fce3;
				transition: 0.3s;
				display:none;
			}
			#blog-btn7:hover{
				background: #94d82d;
			}
			#blog-btn8{
				background: #fff0f6;
				transition: 0.3s;
				display:none;
			}
			#blog-btn8:hover{
				background: #f06595;
			}
			#blog-btn9{
				background: #fff4e6;
				transition: 0.3s;
				display:none;
			}
			#blog-btn9:hover{
				background: #ff922b;
			}
			#blog-btn10{
				background: #ebfbee;
				transition: 0.3s;
			}
			#blog-btn10:hover{
				background: #51cf66;
			}
			#blog-btn11{
				background: #f8f0fc;
				transition: 0.3s;
			}
			#blog-btn11:hover{
				background: #cc5de8;
			}
			
			#blog-btn12{
				background: rgba(0,0,0,1);
				transition: 0.3s;
			}
			#blog-btn12:hover{
				background: #fff;
			}
.desc{
	font-family:"Pacifico";
}
.gradient-text {
  /* Fallback: Set a background color. */
  color: #CA4246;

}

.gradient-text:hover{
    color: #9f2d31;
}
.pricing-card {
    flex-direction: column;
    min-width: 0;
    color: #000;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid #fff;
    border-radius: 6px;
    box-shadow: 0px 0px 5px 0px rgb(161, 163, 164)
}

.social-icon a{
	padding:5px;
	height:45px;
	width:45px;
	font-size:20px;
	display:inline-block;
	margin-right:7px;
	border-radius:50%;
	color:#fff;
	background:#303030;
	transition:0.3s;
	box-shadow:5px 5px 8px #000;
}
.social-icon a:hover{
	box-shadow:10px 10px 5px #000;
}

/*Project.js*/
.carousel .col-md-6:nth-child(1) .card img{
	border-top: 5px solid #f9c803;
	border-radius: 5px;
}
.carousel .col-md-6:nth-child(2) .card img, button{
	border-top: 5px solid #2eb9d6;
	border-radius: 5px;
				}
.carousel .card {
	border: 0;
	box-shadow: inset 0px 0px 20px rgba(0,0,0,0.5);
}
.carousel .col-md-6:nth-child(1) .card button{
	background: #f9c803;
	color: #fff;
	font-size: 15px;
}
.carousel .col-md-6:nth-child(2) .card button{
	background: #2eb9d6;
	color: #fff;
	font-size: 15px;
}


.scn-screen {
width: 100%;
  margin: auto;
  box-shadow: 0px 20px 46px 2px #bcc6ff;
  cursor: n-resize;
}
.scn-screen img {
  width: 100%;
  object-fit: cover;
  object-position: top;
  height: 450px;
 transition: 8s all ease;
}
.scn-screen:hover img {
	object-position: bottom;
}



/* ----------- ON Mobile Phone --------------*/
@media only screen and (max-width: 700px) {
	.notch-container{
		display:none;
	}
	.scn-screen img{
		height:250px;
	}
}
